// Hàm để ghi email vào localStorage
export const subscribeEmail = (email) => {
    const subscribedEmails = JSON.parse(localStorage.getItem('subscribedEmails')) || [];
  
    // Kiểm tra nếu email đã tồn tại
    if (subscribedEmails.includes(email)) {
      return { success: false, message: 'Email này đã được đăng ký!' };
    }
  
    // Thêm email vào danh sách
    subscribedEmails.push(email);
    localStorage.setItem('subscribedEmails', JSON.stringify(subscribedEmails));
  
    return { success: true, message: 'Đăng ký thành công!' };
  };
  