import React from 'react';
import './EventPage.css';

const EventPage = () => {
  return (
    <div className="event-page">
      <h1>Events</h1>
      <p>This page provides events for users.</p>
    </div>
  );
};

export default EventPage;
