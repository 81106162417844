import React from 'react';
import './HomePage.css';

const HomePage = () => {
  return (
    <div className="homepage">
      <div className="homepage-container">
        <h1 className="homepage-title">Welcome to Our Website</h1>
        <p className="homepage-description">
          Discover our amazing tools, blogs, and services tailored just for you.
        </p>
        <div className="homepage-buttons">
          <a href="/tools" className="homepage-button">Explore Tools</a>
          <a href="/blogs" className="homepage-button">Read Blogs</a>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
