import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from '../assets/images/logo.jpg'; // Đường dẫn đến logo của bạn

const Header = () => {
  return (
    <header className="header">
      <div className="header__logo-container">
        <Link to="/">
          <img src={logo} alt="Website Logo" className="header__logo" />
        </Link>
      </div>
      <nav className="header__nav">
        <ul className="header__nav-list">
          <li className="header__nav-item"><Link to="/products" className="header__nav-link">Danh mục sản phẩm</Link></li>
          <li className="header__nav-item"><Link to="/news" className="header__nav-link">Tin tức</Link></li>
          <li className="header__nav-item"><Link to="/events" className="header__nav-link">Sự kiện</Link></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
