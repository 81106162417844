import React from 'react';
import './ProductPage.css';

const ProductPage = () => {
  return (
    <div className="event-page">
      <h1>Products</h1>
      <p>This page provides product for users.</p>
    </div>
  );
};

export default ProductPage;
