import React, { useState } from 'react';
import './Footer.css';
import { subscribeEmail } from '../services/SubscribeEmail';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubscribe = (e) => {
    e.preventDefault();
    const result = subscribeEmail(email);
    setMessage(result.message);
    if (result.success) {
      setEmail('');
    }
  };

  return (
    <footer className="footer">
      <div className="footer__logo-container">
        <img src="logo.jpg" alt="Logo" className="footer__logo" />
      </div>
      <div className="footer__subscribe">
        <form onSubmit={handleSubscribe} className="footer__form">
          <input
            type="email"
            placeholder="Nhập email của bạn"
            value={email}
            onChange={handleEmailChange}
            className="footer__input"
            required
          />
          <button type="submit" className="footer__button">Đăng ký</button>
        </form>
        {message && <p className="footer__message">{message}</p>}
      </div>
      <div className="footer__bottom">
        <p>© 2024 Your Company. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
